'use client'

import { ButtonSecondary as Button } from 'fukku/Button/Secondary'
import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import type { ProductColor } from 'product/types'
import { useRef } from 'react'

import { ColorSheet } from '../ColorSheet'

import styles from './ColorSheetButton.module.scss'

interface ColorSheetButtonProps {
	productColors: ProductColor[]
}

export const ColorSheetButton = ({ productColors }: ColorSheetButtonProps) => {
	const { t } = useLabels()
	const settings = useProductCardConsumerLayoutContext()
	const { selectedColorId } = useProductCardContext()

	const ModalSheetRef = useRef<ModalAPI>(null)
	const onOpen = () => ModalSheetRef.current?.show()
	const onClose = () => ModalSheetRef.current?.close()

	const haveColors = productColors?.length > 1
	const currentColor = productColors.find(
		(color) => color.id === selectedColorId
	)

	const shouldRenderColorSheetButton =
		haveColors && settings.shouldRenderColorSheetButton

	if (shouldRenderColorSheetButton) {
		return (
			<div className={styles.colorSheetContainerButton}>
				<Button fullWidth onClick={onOpen}>
					{t('productCard.showColors.button')}
				</Button>

				<Modal
					data-testid='productCard.colorSheet'
					name='productCard-color-sheet'
					title={t('productCard.selectColor.title')}
					subtitle={currentColor?.label}
					type={MODAL_TYPE.SHEET_SMALL}
					showTitle={true}
					ref={ModalSheetRef}
				>
					<ColorSheet productColors={productColors} onClose={onClose} />
				</Modal>
			</div>
		)
	}

	return null
}
