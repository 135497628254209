import type { ProductSize } from 'product/types'

interface FindRelatedModelSizesProps {
	productSizes: ProductSize[] | undefined
}

const RELATED_PRODUCT_ID = 'relatedProductId'

export const findRelatedModelSizes = ({
	productSizes,
}: FindRelatedModelSizesProps) => {
	if (productSizes) {
		return (
			productSizes.filter((productSize) => RELATED_PRODUCT_ID in productSize) ??
			null
		)
	}

	return null
}
