'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { AddToBagButton } from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagButton'
import { AddToBagOneSizeButton } from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagOneSizeButton'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardHoverContext } from 'product-card/hooks/useProductCardHoverContext'
import { useProductCardStock } from 'product-card/hooks/useProductCardStock'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { useProductStock } from 'product/services/productStock/client'
import type { Product } from 'product/types'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import styles from './SizePicker.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface SizePickerProps {
	productId: string
	product: Product
}

export const SizePicker = ({ productId, product }: SizePickerProps) => {
	const {
		country: { countryISO },
	} = useMasterData()
	const { isLargeOrGreater } = useResponsive()
	const { t } = useLabels()

	const { selectedColorId, dataTestId } = useProductCardContext()
	const { productColors, selectedColor } = useProductColors({ product })
	const { isHovered } = useProductCardHoverContext()
	const initialProductSizes = selectedColor?.sizes

	const { data: productStock, isLoading: isProductStockLoading } =
		useProductStock({
			productId,
			country: countryISO,
			enabled: isHovered,
		})

	const { productSizesFullInfo } = useProductCardStock({
		productStock,
		productColors,
	})

	const lastUnits = productSizesFullInfo?.some((size) => size.lastUnits)

	const isSingleSize = selectedColor?.sizes.length === 1
	const shouldRenderSizePicker = isHovered && isLargeOrGreater
	const isLoadingOrNoSizes =
		!productSizesFullInfo ||
		productSizesFullInfo.length === 0 ||
		isProductStockLoading

	if (!shouldRenderSizePicker) {
		return null
	}

	if (isSingleSize) {
		return (
			<div
				className={styles.oneSizeContainer}
				data-testid='productCard.oneSizeContainer'
			>
				<AddToBagOneSizeButton
					isOverlay
					size={selectedColor.sizes[0]}
					lastUnits={lastUnits}
				/>
			</div>
		)
	}

	return (
		<div
			className={styles.sizePicker}
			data-testid={dataTestIds.plpProductCardSizePanel(dataTestId)}
		>
			<div className={styles.sizePickerContainer}>
				<p className={`${text.bodyMRegular} ${styles.sizePickerTitle}`}>
					{t('product.general.addSize.selector.title')}
				</p>
				<div className={styles.sizePickerContent}>
					{isLoadingOrNoSizes
						? initialProductSizes?.map((size) => (
								<AddToBagButton
									key={`${size.id}/${selectedColorId}/${productId}`}
									className={cx(styles.sizePickerAddToBagButton)}
									product={product}
									size={size}
								/>
							))
						: productSizesFullInfo.map((size) => (
								<AddToBagButton
									key={`${size.id}/${selectedColorId}/${productId}`}
									className={cx(
										styles.sizePickerAddToBagButton,
										size.lastUnits && styles.lastUnits
									)}
									product={product}
									size={size}
								/>
							))}
				</div>
			</div>
		</div>
	)
}
