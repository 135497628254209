import { ProductCardHoverContext } from 'product-card/context/ProductCardHoverContext'
import { useContext } from 'react'

export const useProductCardHoverContext = () => {
	const productCardHoverContext = useContext(ProductCardHoverContext)

	if (!productCardHoverContext) {
		throw new Error(
			'useProductCardHoverContext must be used within a ProductCardHoverProvider'
		)
	}

	return productCardHoverContext
}
