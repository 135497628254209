'use client'

import { getProductCardSettings } from 'product-card/constants/settings/getProductCardSettings'
import { ProductCardConsumerLayoutContext } from 'product-card/context/ProductCardConsumerLayoutContext'
import { useContext } from 'react'

export const useProductCardConsumerLayoutContext = () => {
	const productCardConsumerLayoutContext = useContext(
		ProductCardConsumerLayoutContext
	)

	if (!productCardConsumerLayoutContext) {
		return getProductCardSettings({ hasOnlineSale: true })
	}

	return productCardConsumerLayoutContext
}
