const AkamaiCuts = {
	CUT_320: 320,
	CUT_368: 368,
	CUT_480: 480,
	CUT_600: 600,
	CUT_640: 640,
	CUT_721: 721,
	CUT_768: 768,
	CUT_800: 800,
	CUT_960: 960,
	CUT_1024: 1024,
	CUT_1256: 1256,
	CUT_1350: 1350,
	CUT_1656: 1656,
	CUT_1920: 1920,
	CUT_2048: 2048,
}

export const akamaiBreakpoints = Object.values(AkamaiCuts)
