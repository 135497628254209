import { ColorSheetTimeoutContext } from 'product-card/context/ColorSheetTimeoutContext'
import { useContext } from 'react'

export const useColorSheetTimeoutContext = () => {
	const colorSheetTimeoutContext = useContext(ColorSheetTimeoutContext)

	if (!colorSheetTimeoutContext) {
		throw new Error(
			'useColorSheetTimeoutContext must be used within a ColorSheetTimeoutProvider'
		)
	}

	return colorSheetTimeoutContext
}
