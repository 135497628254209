'use client'

import { ButtonPrimary } from 'fukku/Button/Primary'
import { ButtonSecondary } from 'fukku/Button/Secondary'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'

interface OpenSizeSheetButtonProps {
	onOpen: () => void | undefined
}

export const OpenSizeSheetButton = ({ onOpen }: OpenSizeSheetButtonProps) => {
	const { shouldRenderAddButtonAsPrimary } =
		useProductCardConsumerLayoutContext()
	const { t } = useLabels()

	const ButtonComponent = shouldRenderAddButtonAsPrimary
		? ButtonPrimary
		: ButtonSecondary

	return (
		<ButtonComponent
			fullWidth
			data-testid='productCard.sizeSheetButton'
			onClick={onOpen}
		>
			{t('productCard.showSizes.button')}
		</ButtonComponent>
	)
}
