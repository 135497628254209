'use client'

import { createContext, useEffect } from 'react'
import type { Labels } from 'types/labels'

import { useSetLabels } from '../../hooks/useSetLabels/useSetLabels'
import type { LabelsContextType } from '../../types'

export const PageLabelsContext = createContext<LabelsContextType | undefined>(
	undefined
)

export function PageLabelsProvider({
	children,
	labels,
}: {
	children: React.ReactNode
	labels: Labels | null
}) {
	const setLabels = useSetLabels()

	useEffect(() => {
		if (labels) {
			// Clean keys without translations
			const labelsWithTranslations = Object.entries(labels).reduce(
				(acc, [key, value]): Labels => {
					if (value && key !== value && value !== '') {
						return {
							...acc,
							[key]: value,
						}
					}
					return acc
				},
				{}
			)
			setLabels(labelsWithTranslations)
		}
	}, [])

	return children
}
