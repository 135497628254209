import { LayoutSetStateMasterDataContext } from 'master-data/contexts/LayoutMasterDataProvider/LayoutMasterDataProvider'
import { useContext } from 'react'

/** @throws {Error} If useSetMasterData is used without MasterDataProvider */
export const useSetMasterData = () => {
	const setMasterData = useContext(LayoutSetStateMasterDataContext)
	if (!setMasterData) {
		throw new Error('useSetMasterData must be used within a MasterDataProvider')
	}
	return setMasterData
}
