'use client'

import type { ProductColor } from 'product/types'
import { applyStylesIf, cx } from 'utils/cx'

import { useProductCardContext } from '../../../hooks/useProductCardContext'

import styles from './ProductTitle.module.scss'

interface ProductTitleProps {
	productName: string
	renderTags: boolean
	withIcon: boolean
	colors: ProductColor[]
}

export const ProductTitle = ({
	productName,
	renderTags: showTags,
	withIcon,
	colors,
}: ProductTitleProps) => {
	const { selectedColorId } = useProductCardContext()
	const selectedColor = colors?.find((color) => color.id === selectedColorId)
	return (
		<>
			<meta
				itemProp='name'
				content={`${productName} - ${selectedColor?.label}`}
			/>
			<p
				className={cx(
					styles.productTitle,
					applyStylesIf(!showTags && withIcon, styles.withIcon)
				)}
			>
				{productName}
			</p>
		</>
	)
}
