'use client'

import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { findPrimaryProductSizes } from 'product-card/utils/findPrimaryProductSizes'
import { findRelatedModelSizes } from 'product-card/utils/findRelatedModelSizes'
import type { ProductColor } from 'product/types'
import type { ProductFullSizeInfo } from 'product/types/Product'
import type { Stock } from 'product/types/Stock'
import { useMemo } from 'react'

export interface UseProductCardStockProps {
	productStock?: Stock
	productColors?: ProductColor[]
}

export const useProductCardStock = ({
	productStock,
	productColors,
}: UseProductCardStockProps) => {
	const { selectedColorId } = useProductCardContext()

	const targetColor = useMemo(
		() => productColors?.find((item) => item.id === selectedColorId),
		[productColors, selectedColorId]
	)

	const productSizes = useMemo(
		() => findPrimaryProductSizes({ productSizes: targetColor?.sizes }),
		[targetColor]
	)

	const totalProductSizes = targetColor?.sizes.length

	const productStockSizes = selectedColorId
		? productStock?.colors[selectedColorId]?.sizes
		: undefined

	const productSizesFullInfo: ProductFullSizeInfo[] | undefined =
		productStockSizes
			? productSizes?.map((size) => ({
					...size,
					...productStockSizes[size.id],
				}))
			: undefined

	const productReladtedModelSizes = useMemo(
		() => findRelatedModelSizes({ productSizes: targetColor?.sizes }),
		[productSizes]
	)

	const hasProductRelatedModelSizes = useMemo(
		() =>
			!!(
				productReladtedModelSizes?.length &&
				productReladtedModelSizes?.length > 1
			),
		[productSizes]
	)

	return {
		productSizes,
		totalProductSizes,
		productStockSizes,
		productSizesFullInfo,
		productReladtedModelSizes,
		hasProductRelatedModelSizes,
	}
}
