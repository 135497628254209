'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { AddToBagButton } from 'product-card/components/shared/ProductSizes/AddToBag/AddToBagButton'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardStock } from 'product-card/hooks/useProductCardStock'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { sendShowSizeSelectorEvent } from 'product/analytics/sendShowSizeSelectorEvent'
import { useProductStock } from 'product/services/productStock/client/useProductStock'
import type { Product } from 'product/types/Product'
import { useEffect } from 'react'
import { cx } from 'utils/cx'

import styles from './SizeSheet.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface SizeSheetProps {
	product: Product
	onClose: () => void
}

export const SizeSheet = ({ product, onClose }: SizeSheetProps) => {
	const { t } = useLabels()
	const {
		country: { countryISO },
	} = useMasterData()
	const { selectedColorId, productId, location } = useProductCardContext()

	const { productColors, selectedColor } = useProductColors({ product })
	const { data: productStock, isLoading: isStockLoading } = useProductStock({
		productId,
		country: countryISO,
	})
	const initialProductSizes = selectedColor?.sizes
	const { productSizesFullInfo } = useProductCardStock({
		productStock,
		productColors,
	})

	const isLoadingOrNoSizes =
		!productSizesFullInfo || productSizesFullInfo.length === 0 || isStockLoading

	useEffect(() => {
		if (!isStockLoading && productSizesFullInfo) {
			sendShowSizeSelectorEvent({
				productId,
				location,
				colorId: selectedColorId,
				lastUnits: productSizesFullInfo.some((size) => size.lastUnits),
			})
		}
	}, [isStockLoading, productSizesFullInfo])

	if (productSizesFullInfo) {
		return (
			<div
				data-testid='productCard.sizeSheetContent'
				className={styles.sizeSheetBody}
			>
				{isLoadingOrNoSizes
					? initialProductSizes?.map((size) => (
							<div
								key={`${size.id}/${selectedColorId}/${productId}`}
								className={styles.sizeSheetAddToBagButtonContainer}
							>
								<AddToBagButton
									className={cx(
										text.bodyMRegular,
										styles.sizeSheetAddToBagButton
									)}
									size={size}
									product={product}
									onClose={onClose}
								/>
							</div>
						))
					: productSizesFullInfo.map((size) => (
							<div
								key={`${size.id}/${selectedColorId}/${productId}`}
								className={styles.sizeSheetAddToBagButtonContainer}
							>
								<AddToBagButton
									className={cx(
										text.bodyMRegular,
										styles.sizeSheetAddToBagButton,
										size.lastUnits && styles.lastUnits
									)}
									size={size}
									product={product}
									onClose={onClose}
								>
									{size.lastUnits && (
										<span
											className={`${styles.lastUnitsText} ${text.bodyMRegular}`}
										>
											{t('pdp.sizeSelector.lastItems.text')}
										</span>
									)}

									{!size.available && (
										<span
											className={`${styles.backInStockText} ${text.bodyMRegular}`}
										>
											{t('product.sizeSelector.notifyMe.link')}
										</span>
									)}
								</AddToBagButton>
							</div>
						))}
			</div>
		)
	}

	return null
}
