import type {
	Dispatch,
	MouseEvent,
	MutableRefObject,
	SetStateAction,
	TouchEvent,
} from 'react'

export interface SlideshowProps {
	children: JSX.Element[]
	className: string
	dataTestId?: string
	hasPreload?: boolean
	isTouchable?: boolean
	labels?: { previous: string; next: string }
	onSwipe?: () => void
}

export interface UseSlideshow {
	bulletIndex: number
	items: JSX.Element[]
	onArrowClick: (
		event: MouseEvent<HTMLButtonElement>,
		direction: SlideshowFromDirection
	) => void
	onNavigation: (fromDirection: SlideshowFromDirection) => void
	onNavigationMouseEnter: (fromDirection: SlideshowFromDirection) => void
	onTouchEnd: () => void
	onTouchMove: (touchEvent: TouchEvent<HTMLDivElement>) => void
	onTouchStart: (touchEvent: TouchEvent<HTMLDivElement>) => void
	onTransitionEnd: () => void
	preloadedItems: JSX.Element[] | undefined
	slideshowRef: MutableRefObject<HTMLDivElement | null>
}

export interface UseSlideshowProps {
	children: SlideshowProps['children']
	hasPreload?: SlideshowProps['hasPreload']
}

export interface UseSlideshowUtilsProps {
	activeItemIndexRef: MutableRefObject<number>
	beforeSlideOffsetRef: MutableRefObject<number>
	bulletIndex: number
	children: UseSlideshowProps['children']
	itemWidthRef: MutableRefObject<number>
	setBulletIndex: Dispatch<SetStateAction<number>>
	slideshowRef: MutableRefObject<HTMLDivElement | null>
}

export interface UseSlideshowUtils {
	getNewIndexFromDirection: (
		currentIndex: number,
		fromDirection: SlideshowFromDirection
	) => number
	getNextItemToPreload: (
		currentIndex: number,
		fromDirection: SlideshowFromDirection
	) => JSX.Element
	getRtlDirection: (direction: SlideshowFromDirection) => SlideshowFromDirection
	getSlideshowWidth: () => number
	handleLoop: (
		fromDirection: SlideshowFromDirection,
		shouldChangeSlide?: boolean
	) => void
	handleSlide: (toPreviousSlide: boolean) => void
	onSlideshowResize: () => void
	restoreSlide: () => void
	updateBulletsByDirection: (fromDirection: SlideshowFromDirection) => number
	updateStyles: ({
		offset,
		transitionDuration,
	}: UpdateSlideshowStylesProps) => void
}

export interface UpdateSlideshowStylesProps {
	offset?: number
	transitionDuration?: number
}

export const enum SlideshowFromDirection {
	LEFT = 'left',
	RIGHT = 'right',
}

export interface SlideshowIndexDirectionsMap {
	[SlideshowFromDirection.LEFT]: number
	[SlideshowFromDirection.RIGHT]: number
}
