'use client'

import { IconNextS } from 'icons/components/IconNextS'
import { IconPrevSmall } from 'icons/components/IconPrevSmall'
import { type CSSProperties } from 'react'
import { cx } from 'utils/cx'

import { useSlideshow } from './hooks/useSlideshow'
import { SlideshowFromDirection, type SlideshowProps } from './types/Slideshow'

import styles from './Slideshow.module.scss'

export const INITIAL_OFFSET = 0 // px
export const INITIAL_TRANSITION_DURATION = 0 // ms

export function Slideshow({
	children,
	className,
	labels = { previous: 'previous', next: 'next' },
	isTouchable = true,
	hasPreload = false,
	dataTestId,
	onSwipe,
}: SlideshowProps): JSX.Element {
	const {
		bulletIndex,
		items,
		onArrowClick,
		onNavigationMouseEnter,
		onTouchEnd,
		onTouchMove,
		onTouchStart,
		onTransitionEnd,
		preloadedItems,
		slideshowRef,
	} = useSlideshow({ children, hasPreload })

	return (
		<div
			className={cx(className, styles.slideshowWrapper)}
			data-testid={dataTestId}
		>
			{preloadedItems &&
				preloadedItems.map((preloadItem) => (
					<span
						className={styles.preloadedItem}
						aria-hidden
						key={preloadItem.key}
					>
						{preloadItem}
					</span>
				))}
			<div
				onTransitionEnd={onTransitionEnd}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={() => {
					onSwipe?.()
					onTouchEnd()
				}}
				className={styles.slideshow}
				ref={slideshowRef}
				data-testid='slideshow'
				style={
					{
						'--slideshow-offset': `${INITIAL_OFFSET}px`,
						'--slideshow-transition-duration': `${INITIAL_TRANSITION_DURATION}ms`,
					} as CSSProperties
				}
			>
				{items}
			</div>
			<button
				type='button'
				className={cx(styles.arrow, styles.prevArrow)}
				data-testid={dataTestId && `${dataTestId}.previewItemButton`}
				onClick={(e) => {
					onSwipe?.()
					onArrowClick(e, SlideshowFromDirection.LEFT)
				}}
				onMouseEnter={
					!isTouchable
						? () => onNavigationMouseEnter(SlideshowFromDirection.LEFT)
						: undefined
				}
				aria-label={labels.previous}
			>
				<IconPrevSmall className={styles.prevIcon} />
			</button>
			<button
				type='button'
				className={cx(styles.arrow, styles.nextArrow)}
				data-testid={dataTestId && `${dataTestId}.nextItemButton`}
				onClick={(e) => {
					onSwipe?.()
					onArrowClick(e, SlideshowFromDirection.RIGHT)
				}}
				onMouseEnter={
					!isTouchable
						? () => onNavigationMouseEnter(SlideshowFromDirection.RIGHT)
						: undefined
				}
				aria-label={labels.next}
			>
				<IconNextS className={styles.nextIcon} />
			</button>
			<div className={styles.bullets}>
				{children.map((child, index) => (
					<span
						className={cx(
							styles.bullet,
							index === bulletIndex && styles.active
						)}
						key={child.key}
						data-testid='slideshow.bullet'
					/>
				))}
			</div>
		</div>
	)
}
