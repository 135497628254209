'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSetMasterData } from 'master-data/hooks/useSetMasterData/useSetMasterData'
import { usePathname, useSearchParams } from 'next/navigation'
import { createContext, useEffect, useRef } from 'react'
import type { MasterData } from 'types/masterData'

import { useMasterDataIsReady } from '../../hooks/useMasterDataIsReady/useMasterDataIsReady'

interface PageMasterDataProviderProps {
	masterData: MasterData
	children: React.ReactNode
}

export const MasterDataContext = createContext<MasterData | undefined>(
	undefined
)

export function PageMasterDataProvider({
	masterData,
	children,
}: PageMasterDataProviderProps) {
	const layoutMasterData = useMasterData()
	const { handleUpdateMasterData } = useSetMasterData()
	const pathname = usePathname()
	const searchParams = useSearchParams()
	const { masterDataIsReady, setMasterDataIsReady } = useMasterDataIsReady()

	const firstRender = useRef(true)

	useEffect(() => {
		if (!firstRender.current) {
			firstRender.current = true
		}
	}, [pathname, searchParams])

	useEffect(() => {
		if (
			masterData !== layoutMasterData &&
			firstRender.current &&
			!masterDataIsReady
		) {
			firstRender.current = false
			handleUpdateMasterData(masterData)
		} else if (firstRender.current) {
			firstRender.current = false
			setMasterDataIsReady(true)
		} else {
			// Do nothing...
		}
	}, [layoutMasterData, pathname, searchParams])

	return children
}
