'use client'

import { Domains } from 'monitoring/constants/domains'
import { Types } from 'monitoring/constants/types'
import { useLog } from 'monitoring/sendLog/client/useLog'
import { fetcher } from 'services/rest/fetcher'
import useSWRImmutable from 'swr/immutable'
import { env } from 'utils/envManager'

import { PRODUCT_STOCK_CLIENT_SERVICE_URL } from '../../../constants/constants'
import type { Stock } from '../../../types/Stock'
import { type GetProductStockProps } from '../getProductStock'

export const useProductStock = ({
	country,
	productId,
	enabled,
}: GetProductStockProps) => {
	const basePath = `${PRODUCT_STOCK_CLIENT_SERVICE_URL}${productId}`
	const { sendLog } = useLog()
	const searchParams = new URLSearchParams({
		countryIso: country,
		channelId: env.NEXT_PUBLIC_CHANNEL,
	})

	const url = new URL(basePath, env.NEXT_PUBLIC_SITE_BASE_URL)
	url.search = searchParams.toString()

	const fetchUrl = url.href

	const shouldFetch = enabled ?? true
	const { data, isLoading, error } = useSWRImmutable<Stock>(
		shouldFetch ? fetchUrl : null,
		fetcher,
		{
			revalidateOnMount: true,
		}
	)

	if (error) {
		sendLog({
			domain: Domains.PRODUCT_CARD,
			message: `stock/product service failed for product: ${productId}`,
			type: Types.Error,
		})
	}

	return { data, isLoading }
}
