export function useShouldRenderColorLink({
	showAsFeatured,
	shouldRenderLinkLabels,
	haveColors,
	hasProductRelatedModelSizes,
}: {
	showAsFeatured: boolean // This products is featured and has a landscape image
	shouldRenderLinkLabels: boolean // This product are be shown in two columns grid
	haveColors: boolean // This product more than one color,
	hasProductRelatedModelSizes: boolean // This product has related model sizes,
}) {
	/**
	 * If is a A2 product (showAsFeatured) and have some colors always render the color link
	 */
	const showAsFeaturedWithColors = showAsFeatured && haveColors

	const shouldRenderColorLink =
		showAsFeaturedWithColors || haveColors || hasProductRelatedModelSizes

	const shouldRenderColorAmountLabel =
		showAsFeaturedWithColors || (haveColors && shouldRenderLinkLabels)

	const shouldRenderColorSizesDivider =
		haveColors && hasProductRelatedModelSizes && shouldRenderLinkLabels

	return {
		shouldRenderColorLink,
		shouldRenderColorAmountLabel,
		shouldRenderColorSizesDivider,
	}
}
