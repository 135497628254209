'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { PdpClientLink } from 'links/pdp/client'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductCardStock } from 'product-card/hooks/useProductCardStock'
import { getProductLinkLabel } from 'product-card/utils/getProductLinkLabel/getProductLinkLabel'
import type { Product, ProductColor } from 'product/types'

import { useShouldRenderColorLink } from '../../../hooks/useShouldRenderColorLink'

import styles from './PdpLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface PdpLinkProps {
	product: Product
	productColors: ProductColor[]
	showAsFeatured: boolean
}

const COLOR_SIZE_DIVIDER = ' - '

export const PdpLink = ({
	product,
	productColors,
	showAsFeatured,
}: PdpLinkProps) => {
	const { t } = useLabels()

	const { selectedColorId, lookId, productListId } = useProductCardContext()
	const { hasProductRelatedModelSizes, totalProductSizes } =
		useProductCardStock({
			productColors,
		})
	const { shouldRenderLinkLabels } = useProductCardConsumerLayoutContext()

	const haveColors = productColors?.length > 1

	const placeholderColorValue = { 1: productColors?.length.toString() }

	const {
		shouldRenderColorLink,
		shouldRenderColorAmountLabel,
		shouldRenderColorSizesDivider,
	} = useShouldRenderColorLink({
		showAsFeatured,
		haveColors,
		hasProductRelatedModelSizes,
		shouldRenderLinkLabels,
	})

	const linkLabel = replacePlaceholders(
		t('plp.home.colors.text.withVariable'),
		placeholderColorValue
	)

	const relatedSizes = totalProductSizes
		? { 1: totalProductSizes.toString() }
		: undefined

	const relatedProductSizesLabel = replacePlaceholders(
		t('plp.home.sizes.text.withVariable'),
		relatedSizes
	)
	if (shouldRenderColorLink) {
		return (
			<PdpClientLink
				product={product}
				colorId={selectedColorId}
				lookId={lookId}
				listId={productListId}
				linkProps={{
					className: `${text.bodyS} ${styles.colorLinkTitle}`,
					'data-testid': 'productCard.pdpLink',
					linkProps: {
						'aria-label': `${getProductLinkLabel(product, selectedColorId)} ${linkLabel} ${relatedProductSizesLabel}`,
					},
				}}
			>
				{shouldRenderColorAmountLabel && linkLabel}
				{shouldRenderColorSizesDivider && COLOR_SIZE_DIVIDER}
				{hasProductRelatedModelSizes && relatedProductSizesLabel}
			</PdpClientLink>
		)
	}

	return null
}
