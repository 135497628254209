'use client'

import { ProductCardRefContext } from 'product-card/context/ProductCardRefContext'
import { useContext } from 'react'

export const useProductCardRefContext = () => {
	const productCardRefContext = useContext(ProductCardRefContext)

	if (!productCardRefContext) {
		throw new Error(
			'useProductCardRefContext must be used within a ProductCardRefProvider'
		)
	}

	return productCardRefContext
}
