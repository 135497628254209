import type { ProductSize } from 'product/types'

interface FindPrimaryProductSizesProps {
	productSizes: ProductSize[] | undefined
}

const RELATED_PRODUCT_ID = 'relatedProductId'

export const findPrimaryProductSizes = ({
	productSizes,
}: FindPrimaryProductSizesProps) => {
	if (productSizes) {
		return (
			productSizes.filter(
				(productSize) => !(RELATED_PRODUCT_ID in productSize)
			) ?? null
		)
	}

	return null
}
