'use client'

import { ProductCardContext } from 'product-card/context/ProductCardContext'
import { useContext } from 'react'

export const useProductCardContext = () => {
	const productCardContext = useContext(ProductCardContext)

	if (!productCardContext) {
		throw new Error(
			'useProductCardContext must be used within a ProductCardProvider'
		)
	}

	return productCardContext
}
