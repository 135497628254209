'use client'

import type { Product } from 'product/types'

import { useProductCardConsumerLayoutContext } from '../../../hooks/useProductCardConsumerLayoutContext'
import { SizePicker } from '../ProductSizes/SizePicker'
import { OverlayLabel } from './OverlayLabel'

interface OverlayContentProps {
	productId: string
	product: Product
	className: string
}

export const OverlayContent = ({
	productId,
	product,
	className,
}: OverlayContentProps) => {
	const { hasProductLabels, hasSizePicker } =
		useProductCardConsumerLayoutContext()

	return (
		<div className={className}>
			{hasProductLabels && <OverlayLabel product={product} />}
			{hasSizePicker && <SizePicker productId={productId} product={product} />}
		</div>
	)
}
