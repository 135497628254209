const createDataTestId =
	(suffix: string | 'productCard') =>
	(productId?: string | 'productCard', indexImage?: number) =>
		`${productId}${suffix}${indexImage ?? ''}`

export const dataTestIds = {
	plpProductCardImage: createDataTestId('-slot'),
	plpProductCardFavoriteActive: createDataTestId('.favorite.heart.active'),
	plpProductCardFavoriteInactive: createDataTestId('.favorite.heart.inactive'),
	plpProductCardPlusSize: createDataTestId('.overlay.size.plus'),
	plpProductCardNewNowTag: createDataTestId('.tag.new.now'),
	plpProductCardTag: createDataTestId('.tag'),
	plpProductCardSlideshow: createDataTestId('.slideshow'),
	plpProductCardSizePanel: createDataTestId('.sizeSelector.panel'),
	plpProductCardSizeAvailable: createDataTestId('.sizeSelector.available'),
	plpProductCardSizeUnavailable: createDataTestId('.sizeSelector.unavailable'),
}
