import { akamaiBreakpoints } from './constants/akamaiBreakpoints'

const DECIMAL_PLACES = 2
interface ImageLoaderProps {
	src: string
	width: number
}

interface AkamaiSmartCropLoaderFactoryProps {
	heightCalcFunction: (width: number) => number
}

export const akamaiSmartCropLoaderFactory = ({
	heightCalcFunction,
}: AkamaiSmartCropLoaderFactoryProps) => {
	return function imageLoader({ src, width }: ImageLoaderProps) {
		const viewportBreakpoint =
			akamaiBreakpoints.find((breakpointWidth) => breakpointWidth >= width) ??
			akamaiBreakpoints[akamaiBreakpoints.length - 1]

		const viewportHeight =
			+heightCalcFunction(viewportBreakpoint).toFixed(DECIMAL_PLACES)
		const baseUrl = process.env.NEXT_PUBLIC_SITE_BASE_URL
		const url = new URL(src, baseUrl)
		const currentUrlQueryParams = url.search

		url.search = `${currentUrlQueryParams}&im=SmartCrop,width=${viewportBreakpoint},height=${viewportHeight}&imdensity=1`

		return url.toString()
	}
}
