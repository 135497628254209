'use client'

import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import type { Product } from 'product/types'
import { useMemo } from 'react'

interface UseProductColorsProps {
	product?: Product
}

export const useProductColors = ({ product }: UseProductColorsProps) => {
	const { selectedColorId } = useProductCardContext()

	const productColors = useMemo(() => product?.colors, [product])
	const selectedColor = useMemo(
		() => productColors?.find((item) => item.id === selectedColorId),
		[productColors, selectedColorId]
	)

	return {
		selectedColor,
		productColors,
	}
}
