'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import { findPrimaryProductSizes } from 'product-card/utils/findPrimaryProductSizes'
import type { Product } from 'product/types'
import { Channel } from 'types/masterData'

import styles from './OverlayLabel.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface OverlayLabelProps {
	product: Product
}

interface OverlayLabelFeatureFlag {
	ids: string[]
}

export const OverlayLabel = ({ product }: OverlayLabelProps) => {
	const { t } = useLabels()
	const { variables } = useFeatureFlag(featureFlagKeys.ProductCardOverlayLabel)
	const { selectedColor } = useProductColors({ product })

	const productSizes = selectedColor?.sizes
	const primaryProductSize = findPrimaryProductSizes({ productSizes })
	const { dataTestId } = useProductCardContext()
	const { channel } = useMasterData()

	const overlayLabelFeatureFlag =
		variables?.overlayLabel as OverlayLabelFeatureFlag

	const overlayLabelFeatureFlagIds = overlayLabelFeatureFlag?.ids

	const productFamiliesIds = product.families?.map(
		(productFamily) => productFamily.id
	)

	const sizeLabel = primaryProductSize?.[0].label ?? null
	const shouldRenderSizeLabel = !!(
		sizeLabel &&
		overlayLabelFeatureFlagIds?.length > 0 &&
		productFamiliesIds?.some(
			(id) => overlayLabelFeatureFlagIds.includes(id) ?? false
		)
	)

	const hasPlusSize = productSizes?.some((productSize) => productSize.plusSize)
	const shouldRenderPlusSize =
		hasPlusSize && !shouldRenderSizeLabel && channel !== Channel.Outlet

	if (shouldRenderSizeLabel || shouldRenderPlusSize) {
		return (
			<p
				className={`${styles.overlaylabel} ${text.bodyMRegular}`}
				data-testid={dataTestIds.plpProductCardPlusSize(dataTestId)}
			>
				{shouldRenderSizeLabel && sizeLabel}
				{shouldRenderPlusSize && t('product.woman.availablePlus.label')}
			</p>
		)
	}

	return null
}
