import { cx } from 'utils/cx'

import { BUTTON_VARIANT } from '../Button.types'
import { ButtonBase } from '../ButtonBase'

import styles from './ButtonSecondary.module.scss'

interface SecondaryProps extends React.ComponentProps<'button'> {
	fullWidth?: boolean
	variant?: BUTTON_VARIANT
}

export function ButtonSecondary(props: SecondaryProps): React.ReactNode {
	const {
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		children,
		fullWidth,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(className, styles[variant], fullWidth && styles.fullWidth)}
		>
			<span className={styles.content}>{children}</span>
		</ButtonBase>
	)
}
