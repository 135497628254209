'use client'

import { ProductCardIcon, type ProductCardProps } from 'product-card/types'
import type { ProductColor } from 'product/types'
import type { ProductLocations } from 'product/types/ProductLocations'
import {
	type ReactElement,
	createContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'

export interface ProductCardContextProps {
	productId: string
	setProductId: (productId: string) => void
	dataTestId: string
	lookId: string
	defaultColorId: string
	selectedColorId: ProductColor['id']
	setSelectedColorId: (colorId: string) => void
	shouldFetchProduct: boolean
	setShouldFetchProduct: (shouldFetchProduct: boolean) => void
	location: ProductLocations
	icon: ProductCardIcon
	setIcon: (icon: ProductCardIcon) => void
	productListId?: string
	draggable?: boolean
}

interface ProductCardProviderProps {
	initialProductId: string
	initialLookId: string
	initialDataTestId: string
	initialColorId: string
	location: ProductLocations
	children: ReactElement<ProductCardProps>
	productListId?: string
	noDraggable?: boolean
}

export const ProductCardContext = createContext<ProductCardContextProps | null>(
	null
)

export const ProductCardProvider = ({
	children,
	initialProductId,
	initialDataTestId,
	initialLookId,
	initialColorId,
	location,
	productListId,
	noDraggable,
}: ProductCardProviderProps) => {
	const [productId, setProductId] = useState<string>(initialProductId)
	const dataTestId = useRef<string>(initialDataTestId).current
	const lookId = useRef<string>(initialLookId).current
	const defaultColorId = useRef<string>(initialColorId).current
	const [selectedColorId, setSelectedColorId] = useState<string>(initialColorId)
	const [shouldFetchProduct, setShouldFetchProduct] = useState<boolean>(false)
	const [icon, setIcon] = useState<ProductCardIcon>(ProductCardIcon.None)

	const values = useMemo(
		() => ({
			productId,
			setProductId,
			dataTestId,
			lookId,
			defaultColorId,
			selectedColorId,
			setSelectedColorId,
			shouldFetchProduct,
			setShouldFetchProduct,
			location,
			productListId,
			icon,
			setIcon,
			draggable: !noDraggable,
		}),
		[
			productId,
			setProductId,
			dataTestId,
			lookId,
			selectedColorId,
			setSelectedColorId,
			location,
			productListId,
			icon,
			setIcon,
		]
	)

	useEffect(() => {
		setShouldFetchProduct(true)
	}, [productId])

	return (
		<ProductCardContext.Provider value={values}>
			{children}
		</ProductCardContext.Provider>
	)
}
