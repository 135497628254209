import type { ProductColor } from 'product/types'

export interface ColorPickerInfo {
	colorId: string
	colorLabel: string
	bulletImg: string
	relatedProductId: string | undefined
}

interface ReorderColorsByIdProps {
	productColors: ProductColor[]
	defaultColor: string
}

export const reorderColorsById = ({
	productColors,
	defaultColor,
}: ReorderColorsByIdProps) => {
	const colorPickerInfo: ColorPickerInfo[] = productColors.map((item) => ({
		colorId: item.id,
		colorLabel: item.label,
		bulletImg: item.bulletImg,
		relatedProductId: item.relatedProductId,
	}))

	const index = colorPickerInfo.findIndex((obj) => obj.colorId === defaultColor)
	if (index > 0) {
		const [objectToOrder] = colorPickerInfo.splice(index, 1)
		colorPickerInfo.unshift(objectToOrder)
	}
	return colorPickerInfo
}
