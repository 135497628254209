'use client'

import { ColorSheetButton } from 'product-card/components/shared/ProductColor/ColorSheetButton/ColorSheetButton'
import { SizeSheetButton } from 'product-card/components/shared/ProductSizes/SizeSheetButton'
import type { Product } from 'product/types'

import { useProductColors } from '../../../hooks/useProductColors'

import styles from './ProductCardActionButtons.module.scss'

interface ProductCardActionButtonsProps {
	product: Product
}

export const ProductCardActionButtons = ({
	product,
}: ProductCardActionButtonsProps) => {
	const { productColors } = useProductColors({ product })

	if (!productColors) {
		return null
	}

	return (
		<div className={styles.productCardActionButtons}>
			<ColorSheetButton productColors={productColors} />
			<SizeSheetButton product={product} />
		</div>
	)
}
