'use client'

import { ProductCardImagesSizesContext } from 'product-card/context/ProductCardImagesSizesContext'
import { useContext } from 'react'

export const useProductCardImagesSizesContext = () => {
	const productCardImagesSizesContext = useContext(
		ProductCardImagesSizesContext
	)

	if (!productCardImagesSizesContext) {
		throw new Error(
			'useProductCardImagesSizesContext must be used within a ProductCardImagesSizesProvider'
		)
	}

	return productCardImagesSizesContext
}
