import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'

import { GenericEvents } from '../types/GenericEvents'
import type { ProductLocations } from '../types/ProductLocations'

export function sendShowSizeSelectorEvent({
	colorId,
	productId,
	lastUnits,
	location,
}: {
	colorId: string
	productId: string
	lastUnits: boolean
	location: ProductLocations
}) {
	sendGenericEvent(GenericEvents.SHOW_SIZE_SELECTOR, {
		color: colorId,
		product_id: productId,
		last_units: lastUnits,
		location,
	})
}
