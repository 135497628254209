import { sortImagesByOrder } from 'product-card/utils/sortImagesByOrder'
import type { Product, ProductColor, ProductImageType } from 'product/types'

interface GetSortedImagesProps {
	product: Product
	lookId: string
	colorId: string
	imageSortCriteria: ProductImageType[]
	shouldRenderFeatured: boolean
	showAsFeatured?: boolean
}
const DEFAULT_LOOK_ID = '00'

export const getSortedImages = ({
	product,
	lookId,
	colorId,
	imageSortCriteria,
	shouldRenderFeatured = false,
	showAsFeatured = false,
}: GetSortedImagesProps) => {
	const { colors } = product
	const targetColor = colors.find(
		(element) => element.id === colorId
	) as ProductColor

	const { images } =
		targetColor.looks[lookId] || targetColor.looks[DEFAULT_LOOK_ID]

	return sortImagesByOrder(
		images,
		imageSortCriteria,
		showAsFeatured,
		shouldRenderFeatured
	)
}
