import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'

import { GenericEvents as ProductGenericEvents } from '../types/GenericEvents'
import type { ProductLocations } from '../types/ProductLocations'

export function sendChangeColorEvent({
	eventName,
	colorId,
	productId,
	location,
}: {
	eventName: ProductGenericEvents.PDP_ACTION | ProductGenericEvents.PLP_ACTION
	colorId: string
	productId: string
	location: ProductLocations
}) {
	sendGenericEvent(eventName, {
		label_1: ProductGenericEvents.CHANGE_COLOR,
		color: colorId,
		product_id: productId,
		location,
	})
}
