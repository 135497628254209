'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	type MouseEvent,
	type TouchEvent,
	useEffect,
	useRef,
	useState,
} from 'react'

import {
	SlideshowFromDirection,
	type UseSlideshow,
	type UseSlideshowProps,
} from '../types/Slideshow'
import { useSlideshowUtils } from './useSlideshowUtils'

const MIN_FRACTION_TO_SLIDE = 4
const SCROLLING_FACTOR = 1.1

function getPreviousNextSlide(
	isLeftToRightGesture: boolean,
	isRightToLeftGesture: boolean,
	isRtl: boolean
): {
	toPreviousSlide: boolean
	toNextSlide: boolean
} {
	const toPreviousSlide =
		(!isRtl && isLeftToRightGesture) || (isRtl && isRightToLeftGesture)
	const toNextSlide =
		(!isRtl && isRightToLeftGesture) || (isRtl && isLeftToRightGesture)

	return { toPreviousSlide, toNextSlide }
}

export function useSlideshow({
	children,
	hasPreload,
}: UseSlideshowProps): UseSlideshow {
	const {
		country: { isRtl },
	} = useMasterData()
	/* Bullet management in order to track the current item index */
	const [bulletIndex, setBulletIndex] = useState(0)
	/* Array of items in order to preload them in the DOM */
	const [preloadedItems, setPreloadedItems] = useState<
		JSX.Element[] | undefined
	>()
	/* Slideshow element in order to change its styles and know its width so we know how much offset is needed to apply */
	const slideshowRef = useRef<HTMLDivElement | null>(null)
	/* Touch start position in order to calculate the slide distance */
	const touchStartRef = useRef<number | null>(null)
	/* Touch end position in order to calculate the slide distance */
	const touchEndRef = useRef<number | null>(null)
	/* Current slide offset, so we are able to restore the slide in case of not changing to the next one, and be able to calculate new offsets */
	const beforeSlideOffsetRef = useRef(0)
	/* Item width, so we know how much we must translate the slideshow on each slide */
	const itemWidthRef = useRef(0)
	/* Item DOM index the user is seeing, so we know if a loop must be applied */
	const activeItemIndexRef = useRef(0)

	/* To prevent images slide on scroll user action */
	const verticalOffsetRef = useRef<number>(0)

	const {
		getNextItemToPreload,
		getSlideshowWidth,
		handleLoop,
		handleSlide,
		onSlideshowResize,
		restoreSlide,
		updateBulletsByDirection,
		updateStyles,
		getNewIndexFromDirection,
		getRtlDirection,
	} = useSlideshowUtils({
		activeItemIndexRef,
		beforeSlideOffsetRef,
		bulletIndex,
		children,
		itemWidthRef,
		setBulletIndex,
		slideshowRef,
	})

	const onArrowClick = (
		event: MouseEvent<HTMLButtonElement>,
		direction: SlideshowFromDirection
	): void => {
		event.preventDefault()
		event.stopPropagation()

		const rtlDirection = getRtlDirection(direction)

		setPreloadedItems([
			getNextItemToPreload(
				getNewIndexFromDirection(bulletIndex, rtlDirection),
				rtlDirection
			),
		])

		onNavigation(rtlDirection)
	}

	function onTouchStart(touchEvent: TouchEvent<HTMLDivElement>): void {
		touchEndRef.current = null
		touchStartRef.current = touchEvent.targetTouches[0].clientX
		verticalOffsetRef.current = touchEvent.targetTouches[0].clientY
		// Preload previous and next items
		if (hasPreload) {
			const [previousItem, nextItem] = [
				getNextItemToPreload(bulletIndex, SlideshowFromDirection.LEFT),
				getNextItemToPreload(bulletIndex, SlideshowFromDirection.RIGHT),
			]
			setPreloadedItems([previousItem, nextItem])
		}
	}

	function onTouchMove(touchEvent: TouchEvent<HTMLDivElement>): void {
		touchEndRef.current = touchEvent.targetTouches[0].clientX
		const verticalOffset = Math.abs(
			verticalOffsetRef.current - touchEvent.targetTouches[0].clientY
		)

		if (touchStartRef.current === null) {
			return
		}

		const offsetToAdd = touchEndRef.current - touchStartRef.current
		const isUserScrolling =
			verticalOffset > Math.abs(offsetToAdd) * SCROLLING_FACTOR

		if (isUserScrolling) {
			return
		}

		const newOffset = beforeSlideOffsetRef.current + offsetToAdd
		updateStyles({ offset: newOffset, transitionDuration: 0 })

		const toPreviousSlide = isRtl ? offsetToAdd < 0 : offsetToAdd > 0
		const isFirstItemActive = activeItemIndexRef.current === 0
		const isLastItemActive = activeItemIndexRef.current === children.length - 1

		if (toPreviousSlide && isFirstItemActive) {
			handleLoop(SlideshowFromDirection.LEFT)
		}
		if (!toPreviousSlide && isLastItemActive) {
			handleLoop(SlideshowFromDirection.RIGHT)
		}
	}

	function onTouchEnd(): void {
		if (touchEndRef.current === null || touchStartRef.current === null) {
			return
		}
		const distance = touchEndRef.current - touchStartRef.current
		const minDistanceToSlide = itemWidthRef.current / MIN_FRACTION_TO_SLIDE

		const isLeftToRightGesture = distance > minDistanceToSlide
		const isRightToLeftGesture = distance < -minDistanceToSlide

		const { toPreviousSlide, toNextSlide } = getPreviousNextSlide(
			isLeftToRightGesture,
			isRightToLeftGesture,
			isRtl
		)

		let fromDirection: SlideshowFromDirection

		if (isRtl) {
			fromDirection =
				distance > 0
					? SlideshowFromDirection.RIGHT
					: SlideshowFromDirection.LEFT
		} else {
			fromDirection =
				distance > 0
					? SlideshowFromDirection.LEFT
					: SlideshowFromDirection.RIGHT
		}

		if (toNextSlide || toPreviousSlide) {
			handleSlide(toPreviousSlide)

			const newBulletIndex = updateBulletsByDirection(fromDirection)

			if (hasPreload) {
				setPreloadedItems([getNextItemToPreload(newBulletIndex, fromDirection)])
			}
		} else {
			restoreSlide()
		}
	}

	function onNavigationMouseEnter(fromDirection: SlideshowFromDirection): void {
		if (hasPreload) {
			setPreloadedItems([
				getNextItemToPreload(bulletIndex, getRtlDirection(fromDirection)),
			])
		}
	}

	function onNavigation(fromDirection: SlideshowFromDirection): void {
		const onGoingSlideChange =
			slideshowRef.current?.style.getPropertyValue(
				'--slideshow-transition-duration'
			) !== '0ms'

		const toPreviousSlide = fromDirection === SlideshowFromDirection.LEFT

		const isFirstItemActive = activeItemIndexRef.current === 0
		const isLastItemActive = activeItemIndexRef.current === children.length - 1

		if (!onGoingSlideChange) {
			if (toPreviousSlide && isFirstItemActive) {
				handleLoop(fromDirection, true)
			} else if (!toPreviousSlide && isLastItemActive) {
				handleLoop(fromDirection, true)
			} else {
				handleSlide(toPreviousSlide)
			}

			updateBulletsByDirection(fromDirection)
		}
	}

	function onTransitionEnd(): void {
		updateStyles({
			transitionDuration: 0,
		})
	}

	useEffect(() => {
		itemWidthRef.current = getSlideshowWidth()

		// We have to observe the Slideshow resize in order to adjust the offset
		const observer = new ResizeObserver(onSlideshowResize)

		if (slideshowRef.current) {
			observer.observe(slideshowRef.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [])

	return {
		bulletIndex,
		onArrowClick,
		onNavigation,
		onNavigationMouseEnter,
		onTouchEnd,
		onTouchMove,
		onTouchStart,
		onTransitionEnd,
		preloadedItems,
		slideshowRef,
		items: children,
	}
}
