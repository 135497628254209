import type { ProductImageType } from 'product/types'
import type { ProductImage, ProductLook } from 'product/types/Product'

const PANORAMIC_IMAGE = 'O2'

export const sortImagesByOrder = (
	images: ProductLook['images'],
	order: ProductImageType[],
	isTwoColumnSize: boolean,
	shouldRenderFeatured: boolean
): ProductImage[] => {
	if (isTwoColumnSize && images[PANORAMIC_IMAGE] && shouldRenderFeatured) {
		return [images[PANORAMIC_IMAGE]]
	}
	const sortedImages = order
		.map((key) => images[key])
		.filter(Boolean) as ProductImage[]

	const fallbackImageKey = Object.keys(images)[0] as ProductImageType

	return sortedImages.length
		? sortedImages
		: ([images[fallbackImageKey]] as ProductImage[])
}
