'use client'

import { MODAL_TYPE, Modal, type ModalAPI } from 'fukku/Modal'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { SizeSheet } from 'product-card/components/shared/ProductSizes/SizeSheet'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { useProductColors } from 'product-card/hooks/useProductColors'
import type { Product } from 'product/types'
import { useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { AddToBagOneSizeButton } from '../AddToBag/AddToBagOneSizeButton'
import { OpenSizeSheetButton } from './OpenSizeSheetButton/OpenSizeSheetButton'

import styles from './SizeSheetButton.module.scss'

interface AddToBagButtonProps {
	product: Product
}

export const SizeSheetButton = ({ product }: AddToBagButtonProps) => {
	const sizeSheetRef = useRef<ModalAPI>(null)
	const { t } = useLabels()
	const { isLessThanLarge } = useResponsive()
	const { hasSizeSheetButton } = useProductCardConsumerLayoutContext()
	const { selectedColorId } = useProductCardContext()
	const { productColors } = useProductColors({ product })

	const productColorInfo = productColors?.find(
		(productColor) => productColor.id === selectedColorId
	)

	if (!productColorInfo) {
		return null
	}

	const hasSizes = productColorInfo && productColorInfo.sizes.length > 1
	const isOneSize = productColorInfo.sizes.length === 1

	const shouldRenderSizes = hasSizeSheetButton && isLessThanLarge

	const onOpen = () => sizeSheetRef.current?.show()
	const onClose = () => sizeSheetRef.current?.close()

	if (shouldRenderSizes) {
		if (hasSizes) {
			return (
				<div className={styles.sizeSheetButtonContainer}>
					<OpenSizeSheetButton onOpen={onOpen} />

					<Modal
						data-testid='productCard.sizeSheet'
						name='productCard-size-sheet'
						title={t('productCard.selectSize.title')}
						type={MODAL_TYPE.SHEET_SMALL}
						showTitle={true}
						ref={sizeSheetRef}
					>
						<SizeSheet product={product} onClose={onClose} />
					</Modal>
				</div>
			)
		}

		if (isOneSize) {
			return (
				<div className={styles.sizeSheetButtonContainer}>
					<AddToBagOneSizeButton size={productColorInfo.sizes[0]} />
				</div>
			)
		}
	}

	return null
}
