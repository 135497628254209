import { useCartController } from 'cart/hooks/useCartController'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { dataTestIds } from 'product-card/constants/dataTestIds'
import { useProductCardConsumerLayoutContext } from 'product-card/hooks/useProductCardConsumerLayoutContext'
import { useProductCardContext } from 'product-card/hooks/useProductCardContext'
import { PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY } from 'product/constants/constants'
import type { ProductFullSizeInfo, ProductSize } from 'product/types/Product'
import { cx } from 'utils/cx'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface AddButtonProps {
	size: ProductFullSizeInfo | ProductSize
	isSizeLabel?: boolean
	className?: string
	children?: React.ReactNode
	onClose?: () => void
}

// TODO: remove this when we send the seller in the add to bag request
const PRODUCT_SELLER = 'mango'

export const AddButton = ({
	size,
	isSizeLabel = true,
	className,
	children,
	onClose,
}: AddButtonProps) => {
	const { t } = useLabels()
	const {
		productId,
		selectedColorId,
		lookId,
		dataTestId,
		location,
		productListId,
	} = useProductCardContext()
	const { add } = useCartController()
	const { layoutView } = useProductCardConsumerLayoutContext()

	const isProductFullSizeInfo = PRODUCT_FULLSIZE_INFO_AVAILABILITY_KEY in size
	const isLastUnits = isProductFullSizeInfo && !!size.lastUnits

	const sizeDataTestId =
		dataTestIds[
			isProductFullSizeInfo && size.available
				? 'plpProductCardSizeAvailable'
				: 'plpProductCardSizeUnavailable'
		](dataTestId)

	const handleOnClick = () => {
		add(
			{
				lookId,
				layoutView,
				productId: size.relatedProductId ?? productId,
				sizeId: size.id,
				seller: PRODUCT_SELLER,
				colorId: selectedColorId,
				lastUnits: isLastUnits,
			},
			location,
			productListId
		)

		if (onClose) {
			onClose()
		}
	}

	return (
		<>
			<button
				className={cx(text.bodyL, className)}
				onClick={handleOnClick}
				data-testid={sizeDataTestId}
			>
				{isSizeLabel && size.label}
				{children}
			</button>
			<span className={fukkuStyles.srOnly}>
				{t('product.general.addSize.selector.title')}
			</span>
		</>
	)
}
